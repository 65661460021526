import { Component, inject, effect, Inject, PLATFORM_ID, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { CollectionModule } from '../../collection/collection.module';
import { GeneralModule } from '../../_shared_modules/general/general.module';
import { ModalModule } from '../../modal/modal.module';
import { SignupModule } from '../../auth/signup/signup.module';

import { AccountUser } from '../../_models/user';
import { Campaign, CampaignData, CalculatedData, DiscountCode } from '../../_models/ads';
import { Company } from '../../_models/company';
import { DataPoint, RelatedDataPoint } from '../../_models/collection';

import { AccountService } from '../../_services/account.service';
import { AdsService } from '../../_services/ads.service';
import { CompanyService } from '../../_services/company.service';
import { LanguageService } from '../../_services/language.service';

import { LoginComponent } from '../../auth/login/login.component';

@Component({
  selector: 'app-campaign-detail',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    TranslateModule,
    CollectionModule,
    GeneralModule,
    ModalModule,
    SignupModule,
    LoginComponent,
  ],
  templateUrl: './campaign-detail.component.html',
  styleUrl: './campaign-detail.component.scss'
})
export class CampaignDetailComponent {

    @ViewChild('addCompanyModal') addCompanyModal;
    @ViewChild('selectCompanyModal') selectCompanyModal;
    @ViewChild('signupCompanyModal') signupCompanyModal;
    @ViewChild('loginModal') loginModal;

    /*@Input() set setCompany(company: Company | undefined) {
        if (company) {
            this.company = company;
            this.selectCompany(company);
        }
    }*/

    @Input() set setCompanyId(companyId: number | undefined) {
        if (companyId) {
            this.campaign.company = companyId;
            this.campaign.payment_method = 'invoice'; // default
            this.getCompany();
        }
    }

    @Input() set setCampaignId(id: number | undefined) {
        if (id) {
            this.campaign.id = id;
            this.updateExisting = true;
            this.getCampaign();
        }
    }

    @Output() published = new EventEmitter();

    accountService = inject(AccountService);
    adsService = inject(AdsService);
    companyService = inject(CompanyService);
    languageService = inject(LanguageService);
    router = inject(Router);

    campaign: Campaign = new Campaign();
    company: Company;

    updateExisting: boolean = false;

    loading: boolean = false;
    loadingCompany: boolean = false;
    loadingSupply: boolean = false;
    loadingPublish: boolean = false;

    loggedInAccountUser: AccountUser | undefined | null;

    data: CalculatedData = {
        'total_amount': 0,
        'min_amount_day': 0,
        'max_amount_day': 0,
        'amount_day': 0,
        'min_budget_day': 0,
        'max_budget_day': 0,
        'budget_day': 0,
    };

    rangeStep: number;

    selectedKeyDataPoints: CampaignData = {};

    selectedRdpJobTitles: (RelatedDataPoint[] | undefined) = undefined;
    selectedRdpLocations: (RelatedDataPoint[] | undefined) = undefined;

    jobTitles: string = 'candidates';
    locations: string = '';

    errors: { [key: string]: any } = {};

    showTipAds: boolean = false;
    showTipChat: boolean = false;
    showDeclineReason: boolean = false;

    countryCode: string;
    countryInEU: boolean = false;
    tax: number = 0;

    discount: DiscountCode | undefined;
    validDiscountCode: boolean | undefined = false;
    discountApplied: boolean = false;

    constructor(
        @Inject(PLATFORM_ID) private platformId: Object
    ) {
        effect(() => {
            this.loggedInAccountUser = this.accountService.loggedInAccountUser();

            if (this.loggedInAccountUser && this.loggedInAccountUser.getCompanies().length == 1) {
                this.company = this.loggedInAccountUser.getCompanies()[0];
                this.campaign.company = this.company.id;
                this.campaign.currency = this.company.currency_code;
                this.selectCompany(this.company);
                this.getCompany();
            }

            // set default campaign invoice email
            if (this.loggedInAccountUser) {
                if (this.campaign.invoice_email == '' || this.campaign.invoice_email == null || this.campaign.invoice_email == undefined) {
                    this.campaign.invoice_email = this.loggedInAccountUser.getEmail();
                }
            }

        });

        if (isPlatformBrowser(this.platformId)) {
            window.scrollTo(0, 0);
        }
    }

    isStaff() {
        if (this.loggedInAccountUser && (this.loggedInAccountUser.isAdmin() || this.loggedInAccountUser.isManager())) {
            return true;
        }
        return false;
    }

    getCampaign() {
        this.loading = true;

        this.adsService.getCampaign(this.campaign.id, this.campaign.company).subscribe(
            campaign => {
                this.campaign = campaign;
                this.updateExisting = true;

                this.applyDiscountCode();
                this.getCompany();

                this.data.amount_day = campaign.amount_day;
                this.data.min_amount_day = campaign.min_amount_day;
                this.data.max_amount_day = campaign.max_amount_day;

                this.data.budget_day = campaign.budget_day;
                this.data.min_budget_day = campaign.min_budget_day;
                this.data.max_budget_day = campaign.max_budget_day;

                this.setRangeStep();

                // set basic_job_title and basic_current_location
                if (campaign.rdps) {
                    this.selectedRdpJobTitles = undefined;
                    this.selectedRdpLocations = undefined;

                    for (let rdp of campaign.rdps) {
                        this.setKeyDps(rdp.data_type_key, [rdp.data_point]);

                        const rdpObj = new RelatedDataPoint().deserialize(rdp);

                        if (rdp.data_type_key == 'basic_job_title') {
                            if (this.selectedRdpJobTitles) {
                                this.selectedRdpJobTitles.push(rdpObj);
                            } else {
                                this.selectedRdpJobTitles = [rdpObj];
                            }

                        } else if (rdp.data_type_key == 'basic_current_location') {
                            if (this.selectedRdpLocations) {
                                this.selectedRdpLocations.push(rdpObj);
                            } else {
                                this.selectedRdpLocations = [rdpObj];
                            }
                        }
                    }
                }

                this.loading = false;
            },
            error => {
                console.log(error);
                this.loading = false;
            }
        );

    }

    hasKeyDps(): boolean {
        const keys = ['basic_job_title', 'basic_current_location'];

        for (const key of keys) {
            if (!this.selectedKeyDataPoints[key] || (this.selectedKeyDataPoints[key] && this.selectedKeyDataPoints[key].length === 0)) {
                return false;
            }
        }

        return true;

    }

    setKeyDps(key: string, dps: (DataPoint | null)[]) {

        this.selectedKeyDataPoints[key] = dps;

        if (key === 'basic_job_title') {
            this.jobTitles = dps.map(dp => dp ? dp.value.en : '').join(', ');
        }

        if (key === 'basic_current_location') {
            this.locations = dps.map(dp => dp ? dp.value.en : '').join(', ');
        }

        this.calcCampaignData();

    }

    setType() {
        // set default messages
        if (this.campaign.type == 'ad' && (this.campaign.message_ad == '' || this.campaign.message_ad == null || this.campaign.message_ad == undefined)) {
            this.campaign.message_ad = 'We are looking for ' + this.jobTitles + ' in ' + this.locations;

        }

        if (this.campaign.type == 'dm' && (this.campaign.message_chat == '' || this.campaign.message_chat == null || this.campaign.message_chat == undefined)) {
            this.campaign.message_chat = 'Hi, I\'m looking for ' + this.jobTitles + ' in ' + this.locations + '. Interested to know more? Let me know.';
        }
    }

    getCurrencyCode(): string {
        let currencyCode: string = 'USD';

        if (this.campaign.currency) {
            currencyCode = this.campaign.currency;

        } else if (this.company) {
              currencyCode = this.company.currency_code;

        } else {
            if (this.languageService.getLanguage() == 'sv') {
                currencyCode = 'SEK';

            } else {
                currencyCode = 'USD';
            }
        }
        return currencyCode;
    }

    calcCampaignData() {
        // dont calculate if the campaign is not new
        if (this.updateExisting) {
            return;
        }

        this.loadingSupply = true;

        const currencyCode = this.getCurrencyCode();

        this.adsService.calcCampaignData(this.selectedKeyDataPoints, currencyCode).subscribe(
            data => {
              this.data = data;

              this.campaign.budget_day = data.budget_day;
              this.campaign.min_budget_day = data.min_budget_day;
              this.campaign.max_budget_day = data.max_budget_day;

              this.campaign.amount_day = data.amount_day;
              this.campaign.min_amount_day = data.min_amount_day;
              this.campaign.max_amount_day = data.max_amount_day;

              this.campaign.currency = currencyCode;

              this.setRangeStep();

              this.loadingSupply = false;
            },
            error => {
                this.loadingSupply = false;
            }
        );
    }

    setRangeStep() {
        let stepNominator = 1;
        const diff = this.data.max_budget_day - this.data.min_budget_day;

        //if (diff % 20 === 0) {
        //  stepNominator = 20;
        if (diff % 10 === 0 && this.data.max_budget_day >= 100) {
          stepNominator = 10;
        } else if (diff % 5 === 0 && this.data.max_budget_day >= 50) {
          stepNominator = 5;
        } else if (diff % 3 === 0 && this.data.max_budget_day >= 30) {
          stepNominator = 3;
        } else if (diff % 2 === 0) {
          stepNominator = 2;
        } else {
          stepNominator = 1;
        }

        this.rangeStep = stepNominator;

        /*let s = 20;
        while (s !== 0 && this.data.max_budget_day % s !== 0 && this.data.max_budget_day >= s * 10) {
            stepNominator = s;
            s--;
        }*/

        //this.rangeStep = this.data.max_budget_day / this.data.max_amount_day;

        //this.data.max_budget_day = Math.floor(this.data.max_budget_day / this.data.max_amount_day) * this.data.max_amount_day;
        //this.data.min_budget_day = Math.floor(this.data.min_budget_day / this.data.min_amount_day) * this.data.min_amount_day;
        //console.log('max_budget_day', this.data.max_budget_day);

        //this.rangeStep = Math.ceil((diff) / stepNominator);
        //this.rangeStep = stepNominator;
    }

    getDailySupply() {
        this.loadingSupply = true;

        const currencyCode = this.getCurrencyCode();

        this.adsService.getDailySupply(this.selectedKeyDataPoints, this.campaign.budget_day, currencyCode).subscribe(
            supply => {
                this.campaign.amount_day = supply;
                this.loadingSupply = false;
            },
            error => {
                this.loadingSupply = false;
            }
        );
    }

    closeModals() {
        this.signupCompanyModal.close();
        this.loginModal.close();

        // wait for the user before publishing
        /*setTimeout(() => {
            this.prePublish();
        }, 500);*/
    }

    canPublish() {
        // if logged out - show company signup form
        // signes up the user and creates the company
        // or the user can login via the same modal
        if (!this.loggedInAccountUser) {
            this.signupCompanyModal.open();

        // logged in
        } else {
            this.prePublish();
        }
    }

    // get company details before publishing the job
    prePublish() {

        if (this.loggedInAccountUser) {

            if (!this.company) {

                //console.log("logged in as", this.loggedInAccountUser);
                const cs = this.loggedInAccountUser.getCompanies();

                // if multi company - open modal to select a company
                if (cs.length > 1) {
                    this.selectCompanyModal.open();

                // if single company, auto select it and publish job
                } else if (cs.length == 1) {
                    const c = cs[0];
                    this.publish(c);

                // if no company, open create company modal
                } else {
                    this.addCompanyModal.open();
                }

            } else {
                this.publish(this.company);
            }

        }

    }

    canUpdate() {
        this.publish(this.company);
    }

    publish(company: Company) {
        this.loadingPublish = true;

        this.addCompanyModal.close();

        /*const data = {
            company_id: companyId,
            page_id: pageId,
        };*/

        //this.campaign.company = company.getId();

        // validate inputs
        if (!this.checkCampaign()) {
            this.loadingPublish = false;
            if (isPlatformBrowser(this.platformId)) {
                setTimeout(() => {
                    window.scrollTo(0, 0);
                }, 100);
            }
            return;
        }

        // clean up messages based on selected type
        if (this.campaign.type === 'ad') {
            // if ad type, remove chat message
            this.campaign.message_chat = '';

        } else if (this.campaign.type === 'dm') {
            // if direct message type, remove ad message
            this.campaign.message_ad = '';
        }

        // check discount code
        if (!this.discount || !this.validDiscountCode) {
            this.campaign.discount_code = '';
        }

        if (this.updateExisting) {
            if (confirm('Are you sure you want to update this campaign? All updates are reviewed before publishing.')) {
                this.adsService.updateCampaign(this.campaign, this.selectedKeyDataPoints).subscribe(
                  data => {
                      this.loadingPublish = false;
                      this.selectCompanyModal.close();
                      this.router.navigate(["/company/" + this.campaign.getCompanyId()], { queryParams: { pp: 'ads', campaign: data['ad_id'] } });
                      this.published.emit(data);
                  },
                  error => {
                      //console.log(error);
                      // merge the errors
                      this.errors = { ...this.errors, ...error.error };

                      if (isPlatformBrowser(this.platformId)) {
                          setTimeout(() => {
                              window.scrollTo(0, 0);
                          }, 100);
                      }
                      this.loadingPublish = false;
                });
            }

        } else {

            this.adsService.publishCampaign(this.campaign, this.selectedKeyDataPoints).subscribe(
                data => {
                    this.loadingPublish = false;
                    this.selectCompanyModal.close();
                    this.router.navigate(["/company/" + this.campaign.getCompanyId()], { queryParams: { pp: 'ads', campaign: data['ad_id'] } });
                    this.published.emit(data);
                },
                error => {
                    //console.log(error);
                    // merge the errors
                    this.errors = { ...this.errors, ...error.error };

                    if (isPlatformBrowser(this.platformId)) {
                        setTimeout(() => {
                            window.scrollTo(0, 0);
                        }, 100);
                    }
                    this.loadingPublish = false;
                }
            );
        }

    }

    pauseCampaign() {
        this.adsService.pauseCampaign(this.campaign.id).subscribe(
            data => {
                this.getCampaign();
            },
            error => {
                console.log(error);
            }
        );
    }

    resumeCampaign() {
        this.adsService.resumeCampaign(this.campaign.id).subscribe(
            data => {
                this.getCampaign();
            },
            error => {
                console.log(error);
            }
        );
    }

    cancelCampaign() {
        if (confirm('Are you sure you want to cancel this campaign?')) {
            this.adsService.cancelCampaign(this.campaign.id).subscribe(
                data => {
                    this.getCampaign();
                },
                error => {
                    console.log(error);
                }
            );
        }
    }

    activateCampaign() {
        this.adsService.activateCampaign(this.campaign.id).subscribe(
            data => {
                this.getCampaign();
            },
            error => {
                console.log(error);
            }
        );
    }

    declineCampaign() {
        this.adsService.declineCampaign(this.campaign.id, this.campaign.declined_reason).subscribe(
            data => {
                this.showDeclineReason = false;
                this.getCampaign();
            },
            error => {
                console.log(error);
            }
        );
    }

    // check if fields are filled and valid
    checkCampaign() {
        let hasErrors = false;
        if (!this.company) {
            this.errors['company'] = 'Please select a company';
            hasErrors = true;
        }

        if (!this.campaign.name) {
            this.errors['name'] = 'Please enter a campaign name';
            hasErrors = true;
        }

        if (!this.campaign.payment_method) {
            this.errors['payment_method'] = 'Please select a payment method';
            hasErrors = true;
        }

        if (this.campaign.budget_day < 1) {
            this.errors['budget_day'] = 'Please enter a valid budget';
            hasErrors = true;
        }

        if (!this.hasKeyDps()) {
            this.errors['key_data_points'] = 'Please add all needed data';
            hasErrors = true;
        }

        if (!this.campaign.type) {
            this.errors['type'] = 'Please select your ad placement';
            hasErrors = true;
        }

        if (this.campaign.type === 'ad' && !this.campaign.message_ad) {
            this.errors['message_ad'] = 'Please enter an ad message';
            hasErrors = true;
        }

        // check if the campaign.url is valid starting with http or https
        if (this.campaign.type === 'ad' && this.campaign.url && !this.campaign.url.match(/^(http|https):\/\//)) {
            this.errors['url'] = 'Please enter a valid URL';
            hasErrors = true;
        }

        if (this.campaign.type === 'dm' && !this.campaign.message_chat) {
            this.errors['message_chat'] = 'Please enter a chat message';
            hasErrors = true;
        }

        if (this.campaign.payment_method === 'invoice') {
            if (!this.campaign.invoice_email) {
                this.errors['invoice_email'] = 'Please enter an email address for the invoice';
                hasErrors = true;
            }

            if (!this.campaign.address1) {
                this.errors['invoice_address1'] = 'Please enter an address';
                hasErrors = true;
            }

            if (!this.campaign.zip_code) {
                this.errors['invoice_zip_code'] = 'Please enter a zip code';
                hasErrors = true;
            }

            if (!this.campaign.city) {
                this.errors['invoice_city'] = 'Please enter a city';
                hasErrors = true;
            }

            if (!this.campaign.country) {
                this.errors['invoice_country'] = 'Please select a country';
                hasErrors = true;
            }

            if (this.countryInEU && !this.campaign.vat_nr && this.countryCode != 'SE') {
                this.errors['invoice_vat_nr'] = 'Please enter a VAT number';
                hasErrors = true;
            }
        }

        return !hasErrors;
    }

    isKeyInErrors(key: string) {
        if (!this.errors) {
            return false;
        }
        return key in this.errors;
    }

    clearErrors(keys: string[]) {
        for (const key of keys) {
            if (key in this.errors) {
                delete this.errors[key];
            }
        }
    }

    // add https:// to the url if it's not there
    fixUrl() {
        if (this.campaign.url && !this.campaign.url.match(/^(http|https):\/\//)) {
            this.campaign.url = 'https://' + this.campaign.url;
        }
    }

    selectCompany(company: Company) {
        this.company = company;
        this.campaign.company = company.id;
        this.campaign.currency = company.currency_code;
        this.clearErrors(['company']);
        this.getCompany();

        if (this.selectCompanyModal.isOpen) {
            this.selectCompanyModal.close();
        }
    }

    // used to load the whole company object since company via loggedInAccountUser is not complete
    getCompany() {
        this.loadingCompany = true;

        if (this.campaign.company) {

          // get the whole company object
          this.companyService.getCompany(this.campaign.company).subscribe(
              company => {
                  this.company = company;

                  this.calcCampaignData();

                  if (this.campaign.url == '' || this.campaign.url == null || this.campaign.url == undefined) {
                      this.campaign.url = 'https://hicareer.com/' + this.company.getPageSlug();
                  }

                  // set billing details
                  if (this.campaign.invoice_email == '' || this.campaign.invoice_email == null || this.campaign.invoice_email == undefined) {
                      this.campaign.invoice_email = this.company.email_address1;
                  }
                  if (this.campaign.address1 == '' || this.campaign.address1 == null || this.campaign.address1 == undefined) {
                      this.campaign.address1 = this.company.address1;
                  }
                  if (this.campaign.address2 == '' || this.campaign.address2 == null || this.campaign.address2 == undefined) {
                      this.campaign.address2 = this.company.address2;
                  }
                  if (this.campaign.zip_code == '' || this.campaign.zip_code == null || this.campaign.zip_code == undefined) {
                      this.campaign.zip_code = this.company.zip_code;
                  }
                  if (this.campaign.city == '' || this.campaign.city == null || this.campaign.city == undefined) {
                      this.campaign.city = this.company.city;
                  }
                  if (this.campaign.country == null || this.campaign.country == undefined) {
                      this.campaign.country = this.company.country;
                  }
                  if (this.campaign.vat_nr == null || this.campaign.vat_nr == undefined) {
                      this.campaign.vat_nr = this.company.vat_nr;
                  }

                  this.loadingCompany = false;
              },
              error => {
                  console.log(error);
                  this.loadingCompany = false;
              });
        }
    }

    openAddCompanyModal() {
        this.addCompanyModal.open();
    }

    closeAddCompanyModal(company: any) {
        this.addCompanyModal.close();
    }

    setCountry(code: string, EU: boolean, Vat: number) {
        this.countryCode = code;
        this.countryInEU = EU;
        this.tax = Vat;
    }

    setCountryFromSelect(country: any) {
        if (country) {
            this.clearErrors(['invoice_country']);
            this.campaign.country = country.getId();
            this.setCountry(country.getCode(), country.inEu(), country.getVat());
        }
    }

    applyDiscountCode() {
        this.clearErrors(['discount_code']);

        if (this.campaign.discount_code) {
            this.adsService.getDiscountCode(this.campaign.discount_code, this.campaign.id).subscribe(
                d => {
                    this.discount = d;
                    this.validDiscountCode = true;
                },
                error => {
                    if (error.error.error) {
                        this.errors['discount_code'] = error.error.error;
                    }
                    this.validDiscountCode = false;
                }
            );
        }
    }
}
