import { Deserializable } from "./deserializable";

import { Image } from './media';
import { DataPoint, RelatedDataPoint } from './collection';

export interface CampaignData {
  [key: string]: (DataPoint | null)[]
}

export interface CalculatedData {
  'total_amount': number,
  'min_amount_day': number,
  'max_amount_day': number,
  'amount_day': number,
  'min_budget_day': number,
  'max_budget_day': number,
  'budget_day': number,
}

export class Partner implements Deserializable {
    id: number;
    name: string;
    image: any;
    discount_code: string;
    discount_amount: number;
    discount_currency_code: string;

    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }

    getId() {
        return this.id;
    }

    getName() {
        return this.name;
    }

    getImage() {
        if (this.image) {
            return new Image().deserialize(this.image);
        }
        return new Image();
    }

    getDiscountCode() {
        return this.discount_code;
    }

    getDiscountAmount() {
        return this.discount_amount;
    }

    getDiscountCurrencyCode() {
        return this.discount_currency_code;
    }
}

export class DiscountCode implements Deserializable {
    id: number;
    name: string;
    code: string;
    amount: number;
    currency_code: string;

    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }

    getId() {
        return this.id;
    }

    getName() {
        return this.name;
    }

    getCode() {
        return this.code;
    }

    getAmount() {
        return this.amount;
    }

    getCurrencyCode() {
        return this.currency_code;
    }

}

export class Campaign implements Deserializable {
    id: number;
    account_user: number;
    company: number;
    company_name: string;
    job: number;
    post: number;
    hub: number;
    name: string;

    budget_day: number;
    min_budget_day: number;
    max_budget_day: number;

    amount_day: number;
    max_amount_day: number;
    min_amount_day: number;

    currency: string;
    action: string;
    status: string;
    declined_reason: string;
    start_date: string;
    end_date: string;

    payment_method: string;
    invoice_email: string;
    address1: string;
    address2: string;
    zip_code: string;
    city: string;
    country: number;
    vat_nr: string;

    discount_code: string;

    created: string;

    created_by_full_name: string;
    created_by_image: string;

    type: string;
    url: string;
    message_ad: string;
    message_chat: string;

    rdps: any;

    //events_sum: number;
    reach_sum: number;
    price_sum: number;
    avg_price: number;

    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }

    getId() {
        return this.id;
    }

    getAccountUserId() {
        return this.account_user;
    }

    getCompanyId() {
        return this.company;
    }

    getCompanyName() {
        return this.company_name;
    }

    getJobId() {
        return this.job;
    }

    getPostId() {
        return this.post;
    }

    getHubId() {
        return this.hub;
    }

    getName() {
        return this.name;
    }

    getType() {
        return this.type;
    }

    getUrl() {
        return this.url;
    }

    getBudgetDay() {
        return this.budget_day;
    }

    getEstAmountDay() {
        return this.amount_day;
    }

    getCurrency() {
        return this.currency;
    }

    getAction() {
        return this.action;
    }

    getStatus() {
        return this.status;
    }

    getDeclinedReason() {
        return this.declined_reason;
    }

    getStartDate() {
        return this.start_date;
    }

    getEndDate() {
        return this.end_date;
    }

    getPaymentMethod() {
        return this.payment_method;
    }

    getInvoiceEmail() {
        return this.invoice_email;
    }

    getAddress1() {
        return this.address1;
    }

    getAddress2() {
        return this.address2;
    }

    getZipCode() {
        return this.zip_code;
    }

    getCity() {
        return this.city;
    }

    getCountryId() {
        return this.country;
    }

    getVatNr() {
        return this.vat_nr;
    }

    getDiscountCode() {
        return this.discount_code;
    }

    getCreated() {
        return this.created;
    }

    getCreatedByFullName() {
        return this.created_by_full_name;
    }

    getCreatedByImage() {
        if (this.created_by_image) {
            return new Image().deserialize(this.created_by_image);
        }
        return new Image();
    }

    getMessageAd() {
        return this.message_ad;
    }

    getMessageChat() {
        return this.message_chat;
    }

    getMaxAmountDay() {
        return this.max_amount_day;
    }

    getMinAmountDay() {
        return this.min_amount_day;
    }

    getMinBudgetDay() {
        return this.min_budget_day;
    }

    getMaxBudgetDay() {
        return this.max_budget_day;
    }

    getRdps() {
        return this.rdps;
    }

    getRelatedDataPoints() {
        let l: RelatedDataPoint[] = [];

        if (this.rdps) {
            for (let i of this.rdps) {
                l.push(new RelatedDataPoint().deserialize(i));
            }
        }
        return l;
    }

    getRelatedDataPointsByKey(key: string) {
        let l: RelatedDataPoint[] = [];
        let rdps: RelatedDataPoint[] | null = null;

        if (this.getRelatedDataPoints()) {
            rdps = this.getRelatedDataPoints();
        }

        if (rdps) {
            for (let i of rdps) {
                // get by key
                if (i.getDataTypeKey() == key) {
                    l.push(i);
                }
            }
        }
        return l;
    }

    getDataPointsByKey(key: string) {
        let l: (DataPoint | null)[] = [];
        let rdps: RelatedDataPoint[] | null = null;

        if (this.getRelatedDataPoints()) {
            rdps = this.getRelatedDataPoints();
        }

        if (rdps) {
            for (let i of rdps) {
                // get by key
                if (i.getDataTypeKey() == key) {
                    l.push(i.getDataPoint());
                }
                // get by sub key
                if (i.getSubDataTypeKey() == key) {
                    l.push(i.getSubDataPoint());
                }
            }
        }
        return l;
    }

    /*getEventsSum() {
        return this.events_sum || 0;
    }*/

    getReachSum() {
        return this.reach_sum || 0;
    }

    getPriceSum() {
        return this.price_sum || 0;
    }

    getAvgPrice() {
        return this.avg_price || 0;
    }
}


