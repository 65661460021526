import { environment } from '../../environments/environment';

import { Injectable, signal, inject, effect } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

import { Campaign, CampaignData, CalculatedData, DiscountCode, Partner } from '../_models/ads';


@Injectable({
  providedIn: 'root'
})
export class AdsService {

    http = inject(HttpClient);

    domain = environment.domain;
    prefix = environment.api_prefix;

    constructor() { }


    calcCampaignData(campaignData: CampaignData, currencyCode: string): Observable<CalculatedData> {
        let url = this.domain + this.prefix + '/ad-campaign/data/';
        return this.http.post<CalculatedData>(url, { 'target': campaignData, 'currency_code': currencyCode });
    }

    getDailySupply(campaignData: CampaignData, currentBudget: number, currencyCode: string): Observable<number> {
        let url = this.domain + this.prefix + '/ad-campaign/supply/';
        return this.http.post<number>(url, {
          'target': campaignData,
          'budget_day': currentBudget,
          'currency_code': currencyCode
        });
    }

    publishCampaign(campaign: Campaign, campaignData: CampaignData): Observable<Campaign> {
        let url = this.domain + this.prefix + '/ad-campaign/';
        return this.http.post<Campaign>(url, { 'campaign': campaign, 'target': campaignData });
    }

    updateCampaign(campaign: Campaign, campaignData: CampaignData): Observable<Campaign> {
        let url = this.domain + this.prefix + '/ad-campaign/' + campaign.id + '/?company_id=' + campaign.company;
        return this.http.put<Campaign>(url, { 'campaign': campaign, 'target': campaignData });
    }

    pauseCampaign(campaignId: number): Observable<Campaign> {
        let url = this.domain + this.prefix + '/ad-campaign/' + campaignId + '/pause/';
        return this.http.post<Campaign>(url, {});
    }

    resumeCampaign(campaignId: number): Observable<Campaign> {
        let url = this.domain + this.prefix + '/ad-campaign/' + campaignId + '/resume/';
        return this.http.post<Campaign>(url, {});
    }

    cancelCampaign(campaignId: number): Observable<Campaign> {
        let url = this.domain + this.prefix + '/ad-campaign/' + campaignId + '/cancel/';
        return this.http.post<Campaign>(url, {});
    }

    activateCampaign(campaignId: number): Observable<Campaign> {
        let url = this.domain + this.prefix + '/ad-campaign/' + campaignId + '/activate/';
        return this.http.post<Campaign>(url, {});
    }

    declineCampaign(campaignId: number, reason: string): Observable<Campaign> {
        let url = this.domain + this.prefix + '/ad-campaign/' + campaignId + '/decline/';
        return this.http.post<Campaign>(url, { 'reason': reason });
    }

    getCampaigns(companyId: number | undefined, fromDate: string, toDate: string): Observable<Campaign[]> {
        const time = new Date().getTime();
        let url = this.domain + this.prefix + '/ad-campaign/?time=' + time;
        if (companyId) {
            url = url + '&company_id=' + companyId;
        }
        if (fromDate) {
            url = url + '&from_date=' + fromDate;
        }
        if (toDate) {
            url = url + '&to_date=' + toDate;
        }
        return this.http.get<Campaign[]>(url).pipe(
            map(campaigns => campaigns.map(campaign => new Campaign().deserialize(campaign)))
        );
    }

    getCampaign(id: number | undefined, companyId: number): Observable<Campaign> {
        let url = this.domain + this.prefix + '/ad-campaign/' + id + '/';
        if (companyId) {
            url = url + '?company_id=' + companyId;
        }
        return this.http.get<Campaign>(url).pipe(
            map(campaign => new Campaign().deserialize(campaign))
        );
    }

    getDiscountCode(code: string, campaignId: number): Observable<DiscountCode> {
        let url = this.domain + this.prefix + '/ad-discount/?code=' + code + '&campaign_id=' + campaignId;
        return this.http.get<DiscountCode>(url).pipe(
            map(discountCode => new DiscountCode().deserialize(discountCode))
        );
    }

    getAdsPartner(slug: string): Observable<Partner> {
        let url = this.domain + this.prefix + '/ad-partner/?slug=' + slug;
        return this.http.get<Partner>(url).pipe(
            map(p => new Partner().deserialize(p))
        );
    }
}
