import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

    constructor(
        @Inject(PLATFORM_ID) private platformId: Object,
        private translate: TranslateService,
    ) {
        const browserLang = translate.getBrowserLang();

        if (isPlatformBrowser(this.platformId)) {

            if (typeof localStorage !== 'undefined') {
                let localStorageLang = localStorage.getItem('currentLang');

                if (localStorageLang) {
                    translate.use(localStorageLang.match(/en|sv/) ? localStorageLang : 'en');
                    localStorage.setItem('currentLang', translate.currentLang);
                }
            }

        } else if (browserLang) {
            translate.use(browserLang.match(/en|sv/) ? browserLang : 'en');

        } else {
            translate.use('en');
        }
    }

    setLanguage(lang: string) {
        this.translate.use(lang);

        if (isPlatformBrowser(this.platformId)) {
            localStorage.setItem('currentLang', lang);
        }
    }

    getLanguage() {
        if (isPlatformBrowser(this.platformId)) {
            return localStorage.getItem('currentLang');
        } else {
            return this.translate.currentLang;
        }
    }

}
