<div class="space-y-4 max-w-2xl mx-auto px-2 sm:px-0">

  <!-- status -->
  <div *ngIf="loggedInAccountUser && !loading && updateExisting" class="space-y-6 border border-gray-200 rounded-md p-4">
    <div class="space-y-3">

      <div class="font-semibold" title="ID: {{ campaign.id }}">
        <span *ngIf="campaign.status == 'active'">{{ 'Active' | translate }}</span>
        <span *ngIf="campaign.status == 'paused'">{{ 'Paused' | translate }}</span>
        <span *ngIf="campaign.status == 'cancelled'">{{ 'Cancelled' | translate }}</span>
        <span *ngIf="campaign.status == 'draft'">{{ 'Draft' | translate }}</span>
        <span *ngIf="campaign.status == 'pending'">{{ 'Pending' | translate }}</span>
        <span *ngIf="campaign.status == 'declined'">{{ 'Declined' | translate }}</span>
        <span *ngIf="campaign.status == 'unpaid'">{{ 'Unpaid' | translate }}</span>
      </div>

      <div class="flex items center space-x-3">
        <div *ngIf="campaign.status == 'active'" class="flex-grow">
          <div class="text-sm text-gray-500">
            {{ 'Your campaign is active and will be shown to your target audience.' | translate }}
          </div>
        </div>
        <div *ngIf="campaign.status == 'paused'" class="flex-grow">
          <div class="text-sm text-gray-500">
            {{ 'Your campaign is paused and will not be shown to your target audience.' | translate }}
          </div>
        </div>
        <div *ngIf="campaign.status == 'cancelled'" class="flex-grow">
          <div class="text-sm text-gray-500">
            {{ 'Your campaign is cancelled and will not be shown to your target audience.' | translate }}
          </div>
        </div>
        <div *ngIf="campaign.status == 'draft'" class="flex-grow">
          <div class="text-sm text-gray-500">
            {{ 'Your campaign is a draft and will not be shown to your target audience.' | translate }}
          </div>
        </div>
        <div *ngIf="campaign.status == 'pending'" class="flex-grow">
          <div class="text-sm text-gray-500">
            {{ 'Your campaign is pending and will be reviewed by our team.' | translate }}
          </div>
        </div>
        <div *ngIf="campaign.status == 'declined'" class="flex-grow">
          <div class="text-sm text-gray-500">
            {{ 'Your campaign is declined, because' | translate }}:
            <pre class="text-sm">{{ campaign.declined_reason }}</pre>
          </div>
        </div>
        <div *ngIf="campaign.status == 'unpaid'" class="flex-grow">
          <div class="text-sm text-gray-500">
            {{ 'Your campaign has been stopped due to an unpaid invoice. Please pay to continue.' | translate }}
          </div>
        </div>

      </div>
      <div class="flex gap-3">
        <button *ngIf="campaign.status == 'active'" (click)="pauseCampaign()" class="btn-outline-blue-sm">{{ 'Pause' | translate }}</button>
        <button *ngIf="campaign.status == 'paused'" (click)="resumeCampaign()" class="btn-outline-blue-sm">{{ 'Resume' | translate }}</button>
        <button *ngIf="campaign.status == 'active'" (click)="cancelCampaign()" class="btn-outline-blue-sm">{{ 'Cancel' | translate }}</button>
        <button *ngIf="isStaff() && campaign.status != 'active'" (click)="activateCampaign()" class="btn-outline-blue-sm">{{ 'Activate' | translate }}</button>
        <button *ngIf="isStaff() && campaign.status != 'declined'" (click)="showDeclineReason = !showDeclineReason" class="btn-outline-blue-sm">{{ 'Decline' | translate }}</button>
      </div>
      <div *ngIf="showDeclineReason" class="p-2">
        <textarea [(ngModel)]="campaign.declined_reason" class="w-full border border-gray-200 rounded-md p-2 text-sm" placeholder="{{ 'Reason for declining' | translate }}"></textarea>
        <button (click)="declineCampaign()" class="btn-outline-blue-sm">{{ 'Decline' | translate }}</button>
      </div>
    </div>
  </div>

  <!-- error message -->
  <div *ngIf="(errors && errors | keyvalue) && (errors | keyvalue).length > 0" class="bg-red-100 border border-red-400 text-red-700 text-sm px-4 py-3 rounded mb-2 space-y-2">
    <div *ngFor="let error of errors | keyvalue" role="alert">
      {{ error.value }}
    </div>
  </div>

  <!-- company -->
  <div *ngIf="loggedInAccountUser && !loadingCompany && !loading" class="space-y-6 border border-gray-200 rounded-md p-4">
    <div class="space-y-3">
      <div class="font-semibold">
        {{ 'Company' | translate }}
      </div>
    </div>

    <div class="space-y-3">

      <div *ngIf="company">
        <div class="flex items-center space-x-4">
          <div class="flex-none w-8">
            <img *ngIf="company.page_icon && company.page_icon.img" class="h-8 w-8" [src]="company.page_icon.img" alt="">
            <div *ngIf="!(company.page_icon && company.page_icon.img)" class="h-8 w-8 bg-gray-200 rounded-full"></div>
          </div>
          <div class="grow">
            <div class="font-semibold">{{ company.name }}</div>
            <div class="text-sm text-gray-500">{{ company.industry }}</div>
          </div>
          <div class="flex-none">
            <button *ngIf="!updateExisting" class="btn-outline-gray-xs" (click)="selectCompanyModal.open()">{{ 'Switch' | translate }}</button>
          </div>
        </div>
      </div>

      <div *ngIf="!company" class="space-y-2">
        <ng-container *ngTemplateOutlet="selectCompanyContent"></ng-container>
      </div>

    </div>

  </div>

  <!-- dps -->
  <div *ngIf="!loading" class="space-y-6 border border-gray-200 rounded-md p-4">
    <div class="space-y-3">
      <div class="font-semibold">
        {{ 'Job title' | translate }}
      </div>
      <div>
          <div class="text-sm mb-3">
            {{ 'Select the job titles you want to target.' | translate }}
          </div>
          <app-data-point-filter-btn
              [disabled]="updateExisting"
              [dataTypeKey]="'basic_job_title'"
              [preSelectedDataPoints]="selectedRdpJobTitles"
              [labelText]="'Select' | translate"
              [setHasAccess]="true"
              [singleSelect]="false"
              (selected)="setKeyDps('basic_job_title', $event)">
          </app-data-point-filter-btn>
      </div>
    </div>

    <div class="space-y-3">
      <div class="font-semibold">
        {{ 'Locations' | translate }}
      </div>
      <div>
          <div class="text-sm mb-3">
            {{ 'Add locations to target people in specific areas.' | translate }}
          </div>
          <app-data-point-filter-btn
              [disabled]="updateExisting"
              [dataTypeKey]="'basic_current_location'"
              [preSelectedDataPoints]="selectedRdpLocations"
              [labelText]="'Add' | translate"
              [setHasAccess]="true"
              [singleSelect]="false"
              (selected)="setKeyDps('basic_current_location', $event)">
          </app-data-point-filter-btn>
      </div>
    </div>

    <div *ngIf="hasKeyDps() && !loadingCompany">
      <div class="rounded-md bg-blue-50 p-5">
        <div class="flex flex-row gap-3 justify-center">
          <div *ngIf="data.min_amount_day > 0" class="text-base text-center">
            {{ 'Total target audience' | translate }}: {{ data.total_amount | number }}<br />
            You can reach ~ {{ data.min_amount_day }} - {{ data.max_amount_day }} {{'professionals / day' | translate }}
          </div>
          <div *ngIf="data.min_amount_day == 0 && !loadingSupply" class="text-sm text-orange-600">
            {{ 'We did not fint enough' | translate }} {{ jobTitles | lowercase }}s {{ 'in' | translate }} {{ locations || ('your selected location' | translate) }}.
            {{ 'Please add more locations or change the job title.' | translate }}
          </div>
          <!--
          <div>
              <button class="btn-outline-blue-xs">Preview</button>
          </div>
          -->
        </div>

      </div>
    </div>

  </div>

  <!-- ad -->
  <div *ngIf="hasKeyDps() && campaign.budget_day > 0 && !loading" class="space-y-6 border border-gray-200 rounded-lg p-4">
    <div class="space-y-3">
      <div class="font-semibold">
        {{ 'Placement' | translate }}
      </div>
      <div class="text-sm">
        {{ 'Select how you want to reach your target audience.' | translate }}
      </div>
    </div>

    <div *ngIf="!loggedInAccountUser" class="space-y-6 font-italic">
        {{ 'Please login to continue with this step.' | translate }}
    </div>
    <div *ngIf="loggedInAccountUser" class="space-y-6">

      <!-- ad type -->
      <div class="space-y-2">
        <!-- ad -->
        <div *ngIf="!updateExisting" class="flex items-center space-x-3">
          <input [(ngModel)]="campaign.type" (ngModelChange)="setType()" (change)="clearErrors(['type'])" [disabled]="updateExisting" type="radio" id="ad" name="type" value="ad">
          <label for="ad" class="text-sm" [ngClass]="{ 'text-red-500': isKeyInErrors('type') }">{{ 'Ads' | translate }}</label>
          <div class="relative">
            <i class="bi bi-info-circle text-blue-600" (mouseenter)="showTipAds=true" (mouseleave)="showTipAds=false"></i>
            <div *ngIf="showTipAds" class="absolute z-10 bg-white border border-gray-200 rounded-md p-3 w-52 shadow-md">
              <div class="text-sm text-gray-700">
                {{ 'Reach your target audience with ads. Your ad will be shown on HiCareer and matching users get a notification.' | translate }}
              </div>
            </div>
          </div>
        </div>
        <!-- ad message -->
        <div *ngIf="campaign.type == 'ad'">
          <div class="flex gap-3 items-center">
            <textarea [(ngModel)]="campaign.message_ad" (keydown)="clearErrors(['type', 'message_ad'])" [ngClass]="{ 'border-red-500': isKeyInErrors('message_ad') }" class="w-full border border-gray-200 rounded-md p-2 text-sm"></textarea>
            <i class="bi bi-pencil"></i>
          </div>
          <!--
          <div *ngIf="!editUrl" class="flex gap-3 items-center text-xs text-gray-700 mt-0 mb-3">
            <div>{{ campaign.getUrl() }}</div>
            <button class="btn-outline-gray-xs" (click)="editUrl=true">{{ 'Edit' | translate }}</button>
          </div>
          -->
          <div class="flex gap-3 items-center">
              <input type="text" [(ngModel)]="campaign.url" (keydown)="clearErrors(['url'])" (change)="fixUrl()" [ngClass]="{ 'border-red-500': isKeyInErrors('url') }" class="mt-1 mb-3 w-full border border-gray-200 rounded-md p-2 text-sm" placeholder="https://">
              <i class="bi bi-pencil"></i>
          </div>

          <!-- ad preview -->
          <div *ngIf="campaign.message_ad != '' && campaign.message_ad != null && campaign.message_ad != undefined" class="space-y-3 p-3 border-t border-b border-gray-200">
            <div class="text-xs">
              {{ 'Ad preview' | translate }}
            </div>
            <div class="flex">
              <div class="flex-none img w-10 h-10 z-0">
                <img *ngIf="company && company.page_icon && company.page_icon.img" class="h-8 w-8 rounded-lg" src="{{ company.page_icon.img }}" alt="">
                <div *ngIf="!company || !(company.page_icon && company.page_icon.img)" class="w-10 h-10 bg-gray-200 flex items-center justify-center">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 text-white">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" />
                  </svg>
                </div>
              </div>

              <div class="relative w-full">
                <div class="grow pl-2">
                  <div class="flex gap-2 items-center">

                    <div *ngIf="company" class="text-sm font-bold flex gap-2 items-center">
                      {{ company.page_name || company.name }}
                    </div>
                    <div *ngIf="!company" class="text-sm font-bold flex gap-2 items-center">
                      {{ 'Your company' | translate }}
                    </div>
                  </div>
                  <pre class="p-0 m-0 text-sm" [innerHtml]="campaign.getMessageAd()"></pre>
                </div>
              </div>

            </div>
          </div>

        </div>
      </div>

      <!-- personal message type -->
      <div class="space-y-2">
        <!-- direct message -->
        <div *ngIf="!updateExisting" class="flex items center space-x-3">
          <input [(ngModel)]="campaign.type" (ngModelChange)="setType()" (change)="clearErrors(['type'])" [disabled]="updateExisting" type="radio" id="chat"  name="type" value="dm">
          <label for="chat" class="text-sm" [ngClass]="{ 'text-red-500': isKeyInErrors('type') }">{{ 'Personal message' | translate }}</label>
          <div class="relative">
            <i class="bi bi-info-circle text-blue-600" (mouseenter)="showTipChat=true" (mouseleave)="showTipChat=false"></i>
            <div *ngIf="showTipChat" class="absolute z-10 bg-white border border-gray-200 rounded-md p-3 w-52 shadow-md">
              <div class="text-sm text-gray-700">
                {{ 'Send a personal message to your target audience. Your message will be sent directly to the users inbox.' | translate }}
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="campaign.type == 'dm'" class="space-y-3">
          <div class="flex gap-3 items-center">
            <textarea [(ngModel)]="campaign.message_chat" (keydown)="clearErrors(['type', 'message_chat'])" [ngClass]="{ 'border-red-500': isKeyInErrors('message_chat') }" class="w-full border border-gray-200 rounded-md p-2 text-sm"></textarea>
            <i class="bi bi-pencil"></i>
          </div>
          <!-- message preview -->
          <div *ngIf="campaign.message_chat != '' && campaign.message_chat != null && campaign.message_chat != undefined" class="space-y-3 p-3 border-t border-b border-gray-200">
            <div class="text-xs">
              {{ 'Message preview' | translate }}
            </div>
            <div class="flex">
              <!-- img logged in user -->
              <div *ngIf="!updateExisting" class="flex-none img w-10 h-10 z-0">
                <img *ngIf="loggedInAccountUser && (loggedInAccountUser.getMainImage() && loggedInAccountUser.getMainImage().getImg())" class="h-8 w-8 rounded-lg" src="{{ loggedInAccountUser.getMainImage().getImg() }}" alt="">
                <div *ngIf="!loggedInAccountUser || !(loggedInAccountUser.getMainImage() && loggedInAccountUser.getMainImage().getImg())" class="w-8 h-8 rounded-lg bg-gray-200 flex items-center justify-center">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 text-white">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" />
                  </svg>
                </div>
              </div>
              <!-- img created by user -->
              <div *ngIf="updateExisting" class="flex-none img w-10 h-10 z-0">
                <img *ngIf="campaign.getCreatedByImage() && campaign.getCreatedByImage().getImg()" class="h-8 w-8 rounded-lg" src="{{ campaign.getCreatedByImage().getImg() }}" alt="">
                <div *ngIf="!(campaign.getCreatedByImage() && campaign.getCreatedByImage().getImg())" class="w-8 h-8 rounded-lg bg-gray-200 flex items-center justify-center">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 text-white">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" />
                  </svg>
                </div>
              </div>
              <div class="relative w-full">
                <div class="grow pl-2">
                  <div class="flex gap-2 items-center">

                    <div class="text-sm font-bold flex gap-2 items-center">
                      <span *ngIf="!updateExisting">
                          {{ loggedInAccountUser.getFullName() || ("Your name" | translate) }}
                      </span>
                      <span *ngIf="updateExisting">
                          {{ campaign.getCreatedByFullName() }}
                      </span>
                      <!--
                        <svg
                        *ngIf="loggedInAccountUser.isMember()"
                        class="w-4 h-4 fill-white stroke-blue-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z" />
                        </svg>
                      -->
                    </div>
                  </div>
                  <pre class="p-0 m-0 text-sm" [innerHtml]="campaign.getMessageChat()"></pre>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>


  <!-- budget -->
  <div *ngIf="hasKeyDps() && campaign.budget_day > 0 && !loading" class="space-y-6 border border-gray-200 rounded-md p-4">
    <div class="space-y-3">
      <div class="font-semibold">
        {{ 'Budget' | translate }}
      </div>
      <div class="text-sm">
        {{ 'Set your daily budget to estimate the number of people you can reach per day.' | translate }}
      </div>
    </div>

    <div class="space-y-3">
      <div class="text-center text-blue-600">
        <span class="text-2xl font-semibold">{{ campaign.budget_day | number }}</span>&nbsp;
        <span class="text-base font-semibold">{{ campaign.getCurrency() }} / {{ 'day' | translate }}</span>
      </div>

      <div class="flex justify-between">
        <div class="flex-none">
          <span class="text-base text-gray-500 dark:text-gray-400 pr-3">{{ data.min_budget_day | number }} {{ campaign.getCurrency() }}</span>
        </div>
        <div class="grow">
          <label for="labels-range-input" class="sr-only">Labels range</label>
          <input [(ngModel)]="campaign.budget_day" (change)="getDailySupply()" id="labels-range-input" type="range" [min]="data.min_budget_day" [max]="data.max_budget_day" [step]="rangeStep" class="w-full h-2 bg-blue-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700">
        </div>
        <div class="flex-none text-right">
          <span class="text-base text-gray-500 dark:text-gray-400 pl-3">{{ data.max_budget_day | number }} {{ campaign.getCurrency() }}</span>
        </div>
      </div>

      <div class="text-center text-lg">
        <!--
        <span class="font-bold">{{ campaign.amount_day }} {{ campaign.amount_day > 1 && jobTitles ? (jobTitles | lowercase) + "s" : (jobTitles | lowercase) }}</span> messages / day
        -->
        <div>
            <span *ngIf="campaign.type == 'ad'">{{ 'Reach' | translate }}</span>
            <span *ngIf="campaign.type == 'dm'">{{ 'campaign.Message' | translate }}</span>
            ~ <span class="font-bold"> {{ campaign.amount_day }} {{ 'people' | translate }}</span>
            / {{ 'day' | translate }}
            <!--
            <div class="text-xs">( {{ campaign.budget_day / campaign.amount_day | number: '1.0-1' }} {{ campaign.getCurrency() }} {{ 'each' | translate }} )</div>
            -->
        </div>

        <!--
        <div *ngIf="loadingSupply" role="status" class="flex justify-center items-center">
            <svg aria-hidden="true" class="w-5 h-5 text-gray-200 animate-spin fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
            </svg>
            <span class="sr-only">Loading...</span>
        </div>
        -->
      </div>
    </div>


  </div>

  <!-- payment method -->
  <div *ngIf="company && hasKeyDps() && campaign.budget_day > 0 && loggedInAccountUser && !loading" class="space-y-6 border border-gray-200 rounded-md p-4">
    <div class="font-semibold">
      {{ 'Payement method' | translate }}
    </div>

    <div class="space-y-3">
        <!-- credit card -->
        <div class="flex items-center space-x-4">
            <input [(ngModel)]="campaign.payment_method" (change)="clearErrors(['payment_method'])" type="radio" id="credit-card" name="payment-method" value="cc" disabled>
            <label for="credit-card" class="text-sm">{{ 'Credit card' | translate }} (coming soon)</label>
        </div>
        <!-- invoice -->
        <div class="flex items center space-x-4">
            <input [(ngModel)]="campaign.payment_method" (change)="clearErrors(['payment_method'])" type="radio" id="invoice" name="payment-method" value="invoice" checked>
            <label for="invoice" class="text-sm">{{ 'Invoice' | translate }}</label>
        </div>
        <!-- invoice details -->
        <div *ngIf="campaign.payment_method == 'invoice'" class="space-y-3">
            <div class="flex gap-3 items-center">
                <input [(ngModel)]="campaign.invoice_email" (change)="clearErrors(['invoice_email'])" [ngClass]="{ 'border-red-500': isKeyInErrors('invoice_email') }" type="text" class="w-full border border-gray-200 rounded-md p-2 text-sm" placeholder="{{ 'Invoice email address' | translate }}">
            </div>
            <div class="flex gap-3 items-center">
                <input [(ngModel)]="campaign.address1" (change)="clearErrors(['invoice_address1'])" [ngClass]="{ 'border-red-500': isKeyInErrors('invoice_address1') }" type="text" class="w-full border border-gray-200 rounded-md p-2 text-sm" placeholder="{{ 'Address' | translate }}">
            </div>
            <div class="flex gap-3 items-center">
                <input [(ngModel)]="campaign.zip_code" (change)="clearErrors(['invoice_zip_code'])" [ngClass]="{ 'border-red-500': isKeyInErrors('invoice_zip_code') }" type="text" class="w-full border border-gray-200 rounded-md p-2 text-sm" placeholder="{{ 'Zip code' | translate }}">
                <input [(ngModel)]="campaign.city" (change)="clearErrors(['invoice_city'])" [ngClass]="{ 'border-red-500': isKeyInErrors('invoice_city') }" type="text" class="w-full border border-gray-200 rounded-md p-2 text-sm" placeholder="{{ 'City' | translate }}">
            </div>
            <div class="flex gap-3 items-center">
                <app-country-select [countryId]="campaign.country" [showLabel]="false" (selected)="setCountryFromSelect($event)"></app-country-select>
                <input *ngIf="campaign && (countryInEU || campaign.vat_nr) && countryCode != 'SE'" [(ngModel)]="campaign.vat_nr" (change)="clearErrors(['invoice_vat_nr'])" [ngClass]="{ 'border-red-500': isKeyInErrors('invoice_vat_nr') }" type="text" class="w-full border border-gray-200 rounded-md p-2 text-sm" placeholder="{{ 'VAT number' | translate }}">
            </div>
        </div>

    </div>
  </div>

  <div *ngIf="!loggedInAccountUser && hasKeyDps() && campaign.budget_day > 0 && !loading" class="my-3 space-y-3">
    <div>{{ 'Sign up or login to continue.' | translate }}</div>
    <div class="flex gap-2">
        <button class="btn-blue" (click)="signupCompanyModal.open()">{{ 'Sign up' | translate }}</button>
        <button class="btn-outline-blue" (click)="loginModal.open()">{{ 'Login' | translate }}</button>
    </div>
  </div>

  <!-- login / signup && publish -->
  <div *ngIf="hasKeyDps() && campaign.budget_day > 0 && loggedInAccountUser && !loading && campaign.status != 'unpaid'" class="space-y-4 border border-gray-200 rounded-md p-4">

    <div class="space-y-3">
      <div class="font-semibold">
        {{ 'Campaign name' | translate }}
      </div>
      <input [(ngModel)]="campaign.name" (change)="clearErrors(['name'])" [ngClass]="{ 'border-red-500': isKeyInErrors('name') }" type="text" class="w-full border border-gray-200 rounded-md p-2 text-sm" placeholder="{{ 'Campaign name' | translate }}">
    </div>

    <p class="text-sm text-gray-500">
      {{ 'Your campaign runs until you cancel it. You can cancel your campaign at any time. You will only be charged for the audience you reach on the days your campaign was active.' | translate }}
    </p>


    <div class="flex gap-2">
        <input [(ngModel)]="campaign.discount_code" [readonly]="discount && validDiscountCode" type="text" class="w-32 border border-gray-200 rounded-md p-1 text-xs" placeholder="{{ 'Discount Code' | translate }}">
        <button *ngIf="!discount || !validDiscountCode" (click)="applyDiscountCode(); discountApplied = true" class="btn-outline-gray-sm">{{ 'Apply' | translate }}</button>
    </div>
    @if (validDiscountCode == true && discount) {
        <div class="text-green-600">
          {{ discount.getAmount() | number }} {{ discount.getCurrencyCode() }} {{ 'discount applied' | translate }}
        </div>
    } @else if (validDiscountCode == false && discountApplied) {
        <div class="text-red-600">{{ 'Invalid discount code' | translate }}</div>
    }

    <p class="text-sm text-gray-500">
      {{ 'By clicking on Publish, you agree to our' | translate }}
      <a [routerLink]="['/terms-of-service']" target="_blank" class="link">{{ 'Terms of Service' | translate }}</a> {{ 'and' | translate }}
      <a [routerLink]="['/privacy-policy']" target="_blank" class="link">{{ 'Privacy Policy' | translate }}</a>
    </p>
    <div>

      <!-- error message -->
      <div *ngIf="(errors && errors | keyvalue) && (errors | keyvalue).length > 0" class="bg-red-100 border border-red-400 text-red-700 text-sm px-4 py-3 rounded mb-2 space-y-2">
        <div *ngFor="let error of errors | keyvalue" role="alert">
          {{ error.value }}
        </div>
      </div>

      <div>
          <button *ngIf="!updateExisting" type="button" class="btn-blue" (click)="canPublish()" [disabled]="loadingPublish">{{ 'Publish' | translate }}</button>
          <button *ngIf="updateExisting && campaign.status != 'unpaid'" type="button" class="btn-blue" (click)="canUpdate()" [disabled]="loadingPublish">{{ 'Update' | translate }}</button>
      </div>

      <div role="status" *ngIf="loadingPublish">
          <svg aria-hidden="true" class="w-6 h-6 mr-4 text-gray-200 animate-spin fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
              <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
          </svg>
          <span class="sr-only">Loading...</span>
      </div>
    </div>
  </div>



</div>


<app-modal #signupCompanyModal [size]="'md'">
    <app-signup *ngIf="signupCompanyModal.isOpen()" [isCompany]="true" [noNavigation]="true" (loginSuccessful)="closeModals()" (signedUpAccountUser)="closeModals()"></app-signup>
</app-modal>

<app-modal #loginModal [size]="'md'">
    <app-login *ngIf="loginModal.isOpen()" [noNavigation]="true" (loginSuccessful)="closeModals()"></app-login>
</app-modal>

<app-modal #addCompanyModal [size]="'md'">
  <h2 class="mb-4 text-lg font-semibold">{{ 'Add Company' | translate }}</h2>
    <app-company-create-form *ngIf="addCompanyModal.isOpen()" [navigateToCompany]="false" [displayStyle]="'mini'" (createdCompany)="closeAddCompanyModal($event)"></app-company-create-form>
    <p class="mt-2 text-sm text-gray-500">{{ 'Add the company thats associated with the job.' | translate }}</p>
</app-modal>

<app-modal #selectCompanyModal [size]="'md'">
    <ng-container *ngTemplateOutlet="selectCompanyContent"></ng-container>
</app-modal>

<ng-template #selectCompanyContent>

  <div class="space-y-3">
      <div>
        {{ 'Select the company you are going to publish the campaign for:' | translate }}
      </div>
      <div *ngIf="loggedInAccountUser" class="max-h-96 overflow-y-auto space-y-2">
          <div *ngFor="let c of loggedInAccountUser.getCompanies()">
              <button class="btn-outline-blue flex gap-2" (click)="selectCompany(c)">
                <img *ngIf="c.page_icon && c.page_icon.img" class="h-5 w-5" [src]="c.page_icon.img" alt="">
                {{ c.name }}
              </button>
          </div>
      </div>

      <div class="border-t border-gray-200 pt-2 mt-2">
        <button class="btn-outline-blue" (click)="selectCompanyModal.close(); addCompanyModal.open();">{{ 'Add company' | translate }}</button>
      </div>
  </div>

</ng-template>
